import projectInfo from '../../package.json';
import meta from '../metadata.json';

declare global {
  interface Window {
    config: IConfig;
    doPscbPay(params: any): void;
  }
}

// prepare config
const local = window.location.href.indexOf('localhost') !== -1;
const test = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_DEV === '1' || local;

const out: IConfig = { ...window.config };
if (out.card === undefined) {
  out.card = true;
}

//
// version
out.version = projectInfo.version + ', build: ' + meta.build;

// test settings
if (test) {
  out.debug = true;
  if (local) {
    out.localhost = true;
    // out.apiUrl = 'https://app.mybstr.com/api/v1/';
    // out.apiUrl = 'https://showcase-stage.mybstr.com/api/v1/';
    // out.apiUrl = 'http://10.15.101.131:8001/';
    // out.apiUrl = 'https://aspp.igora.ru/api';

    // out.widgetMode = false;
    out.iframeMode = false;

    // old
    out.apiUrl = 'https://app.mybstr.com/api/v1'; // http://localhost:3000/44B01E1VV9EA25
    out.oldAPi = true;
    out.sbp = false;
    out.showDocs = true;
    out.defaultDomain = 'igora.cleverparking.ru';

    // new
    out.apiUrl = 'https://pay.smprc.ru/api'; // http://localhost:3000/44B01E1VV9EA25 43B31C199645BF
    out.oldAPi = false;
    out.sbp = true;
    out.showDocs = true;
    out.defaultDomain = 'pay.nevskycentre.ru';
    out.showEmail = true;
    // out.reqEmail = true;

    // stage
    // out.apiUrl = 'http://10.105.0.132:8001'; // http://localhost:3000/44B01E1VV9EA25
    // out.oldAPi = false;
    // out.sbp = true;
    // out.showDocs = true;
    // out.defaultDomain = 'pay.nevskycentre.ru';

    // igora
    // out.apiUrl = 'https://aspp.igora.ru/api'; // http://localhost:3000/44B01E1VV9EA25
    // out.oldAPi = false;
    // out.sbp = true;
    // out.showDocs = true;
    // out.showEmail = true;
    // out.defaultDomain = 'aspp.igora.ru';
    // out.promo = { img: 'menkkings.svg', link: 'https://menkgroup.ru/' };

    // ?
    out.apiUrl = 'http://10.105.0.132:8020';
    // out.oldAPi = false;
    // out.sbp = true;
  }

  console.log('env: test');
  console.log('API url: ' + out.apiUrl);
}

console.log('version: ' + out.version);

export const config = { ...out };
