import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledTitle = styled.h1<{ center?: boolean }>`
  font-weight: bold;
  font-size: 24px;  
  line-height: 1.25;
  text-align: left;
  color: #072738;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 0 0 1.5em;
  img {
    max-height: 70px;;
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    line-height: 1.25;
    font-size: 16px;
    color: #777;
    margin-left: 20px;
  }

  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      margin: 0 0 3rem;
    `}

  @media(max-width: 639px) {
    font-size: 26px;
  }
`;
