import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const timeout = 15000;

export class PskbApi {
  transport: any;
  transportClass;
  notifyFunc;
  getErrorMessage;

  constructor(params: PskbApiParams) {
    const { TransportClass, notify, getErrorMessage } = params;
    this.transportClass = TransportClass;
    this.notifyFunc = notify;
    this.getErrorMessage = getErrorMessage;
  }

  /**
   * init api
   * @param apiUrl
   */
  init(apiUrl: string) {
    // init
    this.transport = new this.transportClass({
      apiUrl,
      timeout,
      prepareRequest: this.prepareRequest,
      prepareResponse: this.prepareResponse,
      prepareError: this.prepareError,
    });
  }

  /**
   * prepare request
   * @param req
   * @returns
   */
  prepareRequest = (req: AxiosRequestConfig) => {
    return req;
  };

  /**
   * prepare response
   * @param res
   * @returns
   */
  prepareResponse = (res: AxiosResponse) => {
    return res.data;
  };

  /**
   * prepare error
   * @param error
   * @returns
   */
  prepareError = (error: AxiosError) => {
    console.log('🚀 ~ error:', error);
    const msg = '1';
    if (!error.response) {
      this.notify('Ошибка соединения', msg);
    }
    return Promise.reject(msg);
  };

  /**
   * user notify
   * @param title
   * @param message
   */
  notify = (title: string, message: string) => {
    if (this.notifyFunc) {
      this.notifyFunc(title, message);
    }
  };

  /**
   * get call
   * @param method
   * @param params
   * @returns
   */
  async get(method: string, params?: any) {
    return await this.transport.get(method, params);
  }

  /**
   * post call
   * @param method
   * @param params
   * @returns
   */
  async post(method: string, params?: any) {
    return await this.transport.post(method, params);
  }
}
