//import react from 'react';
import { Store } from 'react-notifications-component';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';

import { config } from 'app/config';

moment.locale('ru');
moment.suppressDeprecationWarnings = true;

export function getRand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const notify = (title: string, msg: string = '', other?: any) => {
  other = {
    ...{
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: { duration: 3000, onScreen: true },
    },
    ...other,
  };

  if (!title) {
    title = 'Error';
  }

  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg);
  }

  if (!msg) {
    msg = 'Неизвестная ошибка';
  }

  const params = {
    ...other,
    title: title,
    message: msg,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismissable: { click: true },
  };

  //console.log('notify', params);
  Store.addNotification(params);
};

export const fakeWait = (counts: number) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

/**
 *
 * @param time ms
 * @returns
 */
export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });

export const getError = (code: number, type: string, message: string) => {
  if (message) {
    return message;
  }

  if (config.errors[type] && config.errors[type][code]) {
    return config.errors[type][code].ru;
  }

  return code;
};

/**
 * date time functions
 */
export const stringToDate = (time?: any, format = 'D MMMM YYYY') => {
  const out = moment(time);
  out.locale('ru');
  return out.format(format);
};

export const unixToDate = (time: number, format = 'D MMMM YYYY HH:mm:ss') => {
  const out = moment.unix(time);
  out.locale('ru');
  return out.format(format);
};

/**
 * get date for api
 * @param val
 * @param format
 */
export const getApiDate = (val: Moment, format = 'YYYY-MM-DD HH:mm:ssZ') => {
  return val.format(format);
};

/**
 * get view date
 * @param val
 * @returns
 */
export const getViewDate = (val: any) => {
  const d1 = stringToDate(val, 'D MMM YYYY|HH:mm:ss').split('|');
  return (
    <div className="date-time">
      <b>{d1[0]}</b>
      {d1[1]}
    </div>
  );
};

/**
 * get valute
 * @param val
 * @returns
 */
export const getValute = (val: number) => {
  if (!val) {
    return '';
  }
  return val / 100 + ' ₽';
};

/**
 * download file by link
 * @param link
 * @param blank
 */
export const downloadFile = (link: string, blank = false) => {
  /*
  const blob = new Blob([data], { type: 'text/html' });
  */
  //link.href = window.URL.createObjectURL(blob);
  //let fn = link.split('/');
  //fn = fn[fn.length - 1];
  const el = window.document.createElement('a');
  el.href = link;
  el.download = 'download'; //fn;
  if (blank) {
    el.target = '_blank';
  }
  window.document.body.appendChild(el);
  el.click();
  window.document.body.removeChild(el);
};

/**
 * restart process
 */
export const restart = () => {
  window.location.reload();
};

/**
 * get url params 
 */
export const getUrlParams = (search: string) => {
  const out: Record<string, string> = {};
  const params = new URLSearchParams(search);
  if (params) {
    params.forEach((val, name) => {
      out[name] = val;
    });
  }
  return out;
}