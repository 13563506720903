/**
 * Rfid service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { config } from 'app/config';

/**
 * get token
 * @returns string
 */
export const getToken = async () => {
  return await api.post('token');
};

/**
 * get ticket info
 * @param ticket string
 */
export const getTicketInfo = async (ticket: string, discount?: string, urlParams?: any) => {
  const domain = config.defaultDomain ? config.defaultDomain : window.location.host;
  const params: any = { ticket_number: ticket, parking_domain: domain };
  if (discount) {
    params.discount_hash = discount;
  }
  const out = urlParams ? { ...params, ...urlParams } : params;
  return await api.post('price', out);
};

/**
 * pay
 * @param ticket string
 */
export const pay = async (
  operationId: number,
  price: number,
  type: IPayType,
  idempToken: string,
  email?: string,
  discount?: string,
  payment_price?: number,
  urlParams?: any
) => {
  const params: any = {
    operation_id: operationId,
    price: price,
    idemp_token: idempToken,
    payment_type: type,
  };
  if (email) {
    params.email = email;
  }
  if (discount) {
    params.discount_hash = discount;
    params.payment_price = payment_price;
  }

  const out = urlParams ? { ...params, ...urlParams } : params;
  return await api.post('pay', out);
};

/**
 * get payment status
 * @param sessionId
 * @returns
 */
export const getPaymentStatus = async (paymentId: number) => {
  return await api.post('status', { payment_id: paymentId });
};

/**
 * cancel payment
 * @param paymentId
 * @returns
 */
export const cancelPayment = async (paymentId: number) => {
  return await api.post('cancel', { payment_id: paymentId });
};

/**
 * check success
 * @param paymentId
 * @returns
 */
export const checkSuccess = async (paymentId: number) => {
  return await api.post('check_success', { payment_id: paymentId });
};

export const findByPlate = async (plate: string) => {
  return await api.post('search_by_plate', { vehicle_plate: plate });
};
