import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
//import { userLoginMock } from './mock/user.mock';

export class BstrApi {
  loginMethod = 'token';
  publicMethods = ['token'];
  publicMethods2 = ['token'];
  lastMethod?: string;
  lastParams?: any;
  token?: string; //UserLoginResponse;
  refreshError?: boolean;
  test = false;

  storage;
  protocol;
  transport;
  notifyFunc;
  debug;
  getErrorMessage;

  constructor(params: BstrApiParams) {
    const { ProtocolClass, StorageClass, TransportClass, apiUrl, timeout, debug, notify, getErrorMessage } = params;

    // init
    this.storage = new StorageClass('api');
    this.protocol = new ProtocolClass({ getErrorMessage });
    this.transport = new TransportClass({
      apiUrl,
      timeout,
      prepareRequest: this.prepareRequest,
      prepareResponse: this.prepareResponse,
      prepareError: this.prepareError,
    });
    this.notifyFunc = notify;
    this.debug = debug;
    this.getErrorMessage = getErrorMessage;

    // token
    this.token = this.storage.getItem('token');
  }

  /**
   * is public method
   * @param method
   * @returns
   */
  isPublicMethod = (): boolean => (this.lastMethod ? this.publicMethods.includes(this.lastMethod) : true);

  /**
   * is public method
   * @param method
   * @returns
   */
  isDefencedMethod = (): boolean =>
    this.lastMethod ? !this.publicMethods2.includes(this.lastMethod) && !this.publicMethods.includes(this.lastMethod) : false;

  /**
   * is login method
   * @returns
   */
  isLoginMethod = (): boolean => this.lastMethod === this.loginMethod;

  /**
   * logout user
   */
  logout() {
    this.storage.setItem('token', null);
    this.token = undefined;
    //this.unsetAuth();
  }

  /**
   * get token
   * @returns
   */
  getToken(): string /*UserLoginResponse*/ | undefined {
    return 'Basic S3Y2NTl5YUNoOUZOdTNaOjQ2RjJJMExUYmRrNXR2YmpsS01x'; //{ access_token: 'Basic S3Y2NTl5YUNoOUZOdTNaOjQ2RjJJMExUYmRrNXR2YmpsS01x', refresh_token: '', user_id: '', new_user: false };
    //return this.token;
  }

  getJWTToken(): string | undefined {
    let out = this.token;
    if (this.test) {
      out += '1';
    }
    this.test = false;
    
    return out;
  }

  /**
   * set auth token if needed
   * @param {*} req
   */
  setRequestAuth = (req: AxiosRequestConfig) => {
    // if (!this.isPublicMethod()) {
    if (this.isDefencedMethod()) {
      const token = this.getJWTToken();
      if (token) {
        if (req.headers) {
          req.headers['authorization'] = 'Bearer ' + token; //.access_token;
        }
      }
    } else {
      if (this.isLoginMethod()) {
        const token = this.getToken();
        if (token) {
          if (req.headers) {
            req.headers['Authorization'] = token; //.access_token;
          }
        }
      }
    }
    // }

    return req;
  };

  /**
   * prepare request
   * @param req
   * @returns
   */
  prepareRequest = (req: AxiosRequestConfig) => {
    this.lastMethod = req.url;
    this.lastParams = req.data;

    // packet
    req.data = this.protocol.createPacket(req.url ? req.url : '', req.data);
    //req.url = '';

    // auth
    return this.setRequestAuth(req);
  };

  /**
   * prepare response
   * @param res
   * @returns
   */
  prepareResponse = (res: AxiosResponse) => {
    const isLoginMethod = this.isLoginMethod();
    const out = this.protocol.decodePacket(res, isLoginMethod);

    if (isLoginMethod) {
      const token = out.packet.access_token;
      this.storage.setItem('token', token);
      this.token = token;
      // console.log('🚀 ~ token:', token);
    }
    return out.packet;
  };

  /**
   * refresh token
   */
  refreshToken = async () => {
    // refresh
    await this.post(this.loginMethod).catch(() => {
      this.refreshError = true;
    });

    // repeat last method
    if (!this.refreshError && this.lastMethod) {
      return this.post(this.lastMethod, this.lastParams);
    }
  };

  /**
   * prepare error
   * @param error
   * @returns
   */
  prepareError = (error: AxiosError) => {
    const authError = error.response?.status === 401; // || error.response?.status === 403;

    //console.log('🚀 ~ authError:', authError);
    if (authError) {
      if (this.isLoginMethod() || this.refreshError) {
        this.logout();
        return;
      }
      this.refreshToken(); //logout();
      return;
    }
    const msg = this.protocol.getError(error);
    if (!error.response) {
      this.notify('Ошибка соединения', msg);
    }
    return Promise.reject(msg);
  };

  /**
   * user notify
   * @param title
   * @param message
   */
  notify = (title: string, message: string) => {
    if (this.notifyFunc) {
      this.notifyFunc(title, message);
    }
  };

  /**
   * get call
   * @param method
   * @param params
   * @returns
   */
  async get(method: string, params?: any) {
    return await this.transport.get(method, params);
  }

  /**
   * post call
   * @param method
   * @param params
   * @returns
   */
  async post(method: string, params?: any) {
    return await this.transport.post(method, params);
  }
}
