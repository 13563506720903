/**
 * Text input UI adapter
 *
 * @overview Callback parent component after success login
 */
// import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField, { TextFieldProps } from '@mui/material/TextField';
//import { FormGroup } from './text-input.styled';

export type ITextInputProps = TextFieldProps;

export function TextInput(props: ITextInputProps) {
  const { name, ...rest } = props;

  return <TextField {...rest} id={name} name={name} />;
}

TextInput.defaultProps = {
  fullWidth: true,
  //size: 'small',
  variant: 'standard',
  type: 'text',
};

export default TextInput;
