export function isEmptyObject(obj: Record<string, unknown>): boolean {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export const isEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isPlate = (value: string) => {
  return value.length > 5 && value.length < 10;
};
