import { useState, useEffect } from 'react';

import { Loader, Error } from 'libs/mui';
import { getToken } from 'libs/services';
import Router from './router/router';

const App = () => {
  // console.log('🚀 ~ App');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(true);

  // get token
  useEffect(() => {
    const getData = async () => {
      let error1 = false;

      await getToken().catch((err) => {
        setError(err);
        error1 = true;
      });

      setLoading(false);
    };

    getData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return <Router />;
};

export default App;
