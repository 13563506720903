import { config } from 'app/config';
import { LocalStorage, notify } from 'libs/common';
import { AxiosTransport, JSONProtocol } from 'libs/api';
import { getApiError } from './func';
import { getApiError as getApiErrorOld } from './old/func';
import { BstrApi } from './bstr-api';
import { BstrApi as BstrApiOld } from './old/bstr-api';

let apiClass;
let params;

if (config.oldAPi) {
  apiClass = BstrApiOld;
  params = {
    apiUrl: config.apiUrl,
    timeout: config.failAfter,
    debug: config.debug,
    TransportClass: AxiosTransport,
    ProtocolClass: JSONProtocol,
    StorageClass: LocalStorage,
    getErrorMessage: getApiErrorOld,
    notify,
  };
} else {
  apiClass = BstrApi;
  params = {
    apiUrl: config.apiUrl,
    timeout: config.failAfter,
    debug: config.debug,
    TransportClass: AxiosTransport,
    ProtocolClass: JSONProtocol,
    StorageClass: LocalStorage,
    getErrorMessage: getApiError,
    notify,
  };
}

export const api = new apiClass(params);

export default api;
