import {  notify } from 'libs/common';
import { AxiosTransport } from 'libs/api';
import { getApiError } from './func';
import { PskbApi } from './pskb-api';

const params = {
  TransportClass: AxiosTransport,
  getErrorMessage: getApiError,
  notify,
};
export const pskbapi = new PskbApi(params);

export default pskbapi;
